import React, { Component, createRef } from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import {
  ajax,
  API_CALL_STATUSES,
  getQueryParam,
  getRedirectPathFromUrl,
  getTokenRedirectPathFromUrl,
} from "../utils"
import Progress from "../components/progress"
import FancyInput from "../components/FancyInput"
import RIOLogo from "../assets/img/rio_wordmark.svg"
import PasswordInput from "../components/passwordInputs/PasswordInput"

const getErrorMessage = (err, response) => {
  switch (err?.status) {
    case 422:
      return null
    case 403:
      return "Please verify your email."
    case 401:
      return "Invalid username or password."
    case 500:
      return "Server error. Please try again later."
    default:
      return response?.error || "Failed to login."
  }
}

class Login extends Component {
  state = {
    loginStatus: API_CALL_STATUSES.IDLE,
    email: "",
    password: "",
    errorMessage: "",
    showCaptcha: false,
    grecaptchaValue: "",
    loginChallenge: getQueryParam("login_challenge"),
  }

  grecaptchaRef = createRef()

  onLoginSubmit = e => {
    e.preventDefault()
    const { email, password, loginChallenge } = this.state

    this.setState({ loginStatus: API_CALL_STATUSES.PROGRESS })

    ajax({
      path: `user/login${
        loginChallenge ? `?login_challenge=${loginChallenge}` : ""
      }`,
      type: "POST",
      data: { email, password, login_challenge: loginChallenge },
      success: res => {
        navigate(
          `/authRedirect/${getTokenRedirectPathFromUrl(
            res.data.token,
            res.data.expiryAt
          )}`
        )
      },
      error: (err, response) => {
        if (err.status === 302) {
          /**
           * Login flow for OIDC. (e.g., when using 'Login using rapyuta.io' in amr_ui)
           *
           * This redirect sends the user to the consent page (/auth/src/pages/consent.js)
           */
          window.location.href = response.data.redirect_to
        } else {
          this.setState({
            loginStatus: API_CALL_STATUSES.ERROR,
            errorMessage: getErrorMessage(err, response),
            showCaptcha: false,
          })
        }
      },
    })
  }

  updateValue = e => {
    const {
      target: {
        dataset: { id },
        value,
      },
    } = e
    e.preventDefault()
    this.setState({
      [id]: value,
    })
  }

  render() {
    const {
      loginStatus,
      email,
      password,
      errorMessage,
      loginChallenge,
    } = this.state

    /**
     * Remember Me is also not supported when using rapyuta.io as OIDC provider.
     */
    const shouldShowRememberMe = !Boolean(loginChallenge)

    return (
      <Layout dark stickyFooter bgimage>
        {loginStatus === API_CALL_STATUSES.PROGRESS && <Progress />}

        <div className="container mx-auto flex justify-center items-center h-full">
          <div className="w-full max-w-md">
            <div className="relative flex flex-col w-full p-6 shadow-lg rounded-lg bg-white">
              <div className="text-center mb-8">
                <img
                  className="mx-auto h-12 w-auto"
                  src={RIOLogo}
                  alt="Rapyuta Robotics"
                />
              </div>
              {loginStatus === API_CALL_STATUSES.ERROR && errorMessage && (
                <p className="text-red-500 text-center mb-4">{errorMessage}</p>
              )}
              <form onSubmit={this.onLoginSubmit}>
                <div className="mb-4">
                  <FancyInput
                    data-id="email"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email address"
                    value={email}
                    onChange={this.updateValue}
                    autoComplete="email"
                    required
                  />
                </div>

                <div className="mb-4">
                  <PasswordInput
                    data-id="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={this.updateValue}
                    autoComplete="password"
                    required
                  />
                </div>

                {shouldShowRememberMe && (
                  <div className="mb-4 flex items-center">
                    <input
                      id="rememberMe"
                      type="checkbox"
                      className="form-checkbox text-red-600 w-4 h-4"
                    />
                    <label
                      htmlFor="rememberMe"
                      className="ml-2 mt-1 text-sm text-gray-700"
                    >
                      Remember me
                    </label>
                  </div>
                )}

                <div>
                  <button
                    type="submit"
                    className="w-full bg-red-600 text-white py-2 rounded-lg hover:bg-red-700 transition duration-150"
                  >
                    Sign In
                  </button>
                </div>
              </form>

              {/* Links */}
              <div className="flex justify-between mt-4 text-sm">
                <a
                  href={`/forgotPassword${getRedirectPathFromUrl()}`}
                  className="text-red-600 hover:underline"
                >
                  Forgot password?
                </a>
                <a
                  href={`/register${getRedirectPathFromUrl()}`}
                  className="text-red-600 hover:underline"
                >
                  Create new account
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Login
